<template>
    <button type="button" class="btn-s btn-next-light-blue shadow btn-load-more">
    <i
        v-if="props.loading"
        :disabled="props.loading"
        class="fas fa-circle-notch fa-spin"
    ></i>
        続きを見る
    </button>
</template>
<script lang="ts" src="@/presentation/components/button/load-more/BtnLoadMore.ts" />
<style lang="scss" src="@/presentation/components/button/load-more/BtnLoadMore.scss" scoped>
</style>
